
export const AppMenus = {
    
	navbarTopRightItems: [],
	navbarTopLeftItems: [
  {
    "to": "/menu1",
    "label": "",
    "icon": "pi pi-bell text-primary",
    "iconcolor": "",
    "target": "",
    
  }
],
	navbarSideLeftItems: [
  {
    "to": "/home",
    "label": "Home",
    "icon": "pi pi-th-large text-primary",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/alamat_user",
    "label": "Alamat",
    "icon": "pi pi-at text-primary",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/keranjang/list_user",
    "label": "Keranjang",
    "icon": "pi pi-shopping-bag text-primary",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/pembayaran/validasi_pembayaran",
    "label": "Pesanan",
    "icon": "pi pi-truck text-primary",
    "iconcolor": "",
    "target": "",
    "items": [
      {
        "to": "/pembayaran/validasi_pembayaran",
        "label": "Validasi Pembayaran",
        "icon": "",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/pembayaran/list_admin",
        "label": "Order Item",
        "icon": "",
        "iconcolor": "",
        "target": "",
        
      }
    ]
  },
  {
    "to": "/produk",
    "label": "Produk",
    "icon": "pi pi-bookmark-fill text-primary",
    "iconcolor": "",
    "target": "",
    "items": [
      {
        "to": "/produk",
        "label": "Item",
        "icon": "",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/produk_kategori",
        "label": "Kategori",
        "icon": "",
        "iconcolor": "",
        "target": "",
        
      }
    ]
  },
  {
    "to": "/user",
    "label": "User",
    "icon": "pi pi-users text-primary",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/pembayaran",
    "label": "Pembayaran",
    "icon": "pi pi-money-bill text-primary",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/roles",
    "label": "Setup",
    "icon": "pi pi-sliders-h text-primary",
    "iconcolor": "",
    "target": "",
    "items": [
      {
        "to": "/roles",
        "label": "Roles",
        "icon": "",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/permissions",
        "label": "Permissions",
        "icon": "",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/master_pengiriman",
        "label": "Jenis Pengiriman",
        "icon": "",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/master_status",
        "label": "Status Pembayaran",
        "icon": "",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/master_status_barang",
        "label": "Status Barang",
        "icon": "",
        "iconcolor": "",
        "target": "",
        
      }
    ]
  },
  {
    "to": "/keranjang/laporan_penjualan",
    "label": "Laporan Penjualan",
    "icon": "pi pi-file-edit text-primary",
    "iconcolor": "",
    "target": "",
    
  }
],
	utamaItems: [    
{value: "Ya", label: "Ya"},
	{value: "Tidak", label: "Tidak"}
    ],
	panggilanItems: [    
{value: "Tuan", label: "Tuan"},
	{value: "Nona", label: "Nona"}
    ],
	jenisKelaminItems: [    
{value: "Laki - Laki", label: "Laki - Laki"},
	{value: "Perempuan", label: "Perempuan"}
    ],

    exportFormats: {
        print: {
			label: 'Print',
            icon: 'pi pi-print',
            type: 'print',
            ext: '',
        },
        pdf: {
			label: 'Pdf',
			
            icon: 'pi pi-file-pdf',
            type: 'pdf',
            ext: 'pdf',
        },
        excel: {
			label: 'Excel',
            icon: 'pi pi-file-excel',
            type: 'excel',
            ext: 'xlsx',
        },
        csv: {
			label: 'Csv',
            icon: 'pi pi-table',
            type: 'csv',
            ext: 'csv',
        },
    },
    
}