
import { createRouter, createWebHashHistory } from 'vue-router';

import { useAuth } from 'src/composables/auth';


let routes = [
	//Dashboard routes


//alamat_user routes
			{
				path: '/alamat_user/:index?/:fieldName?/:fieldValue?',
				name: 'alamat_userlist',
				component: () => import('./pages/alamat_user/list.vue'), 
				props: true
			},
			
	
			{ 
				path: '/alamat_user/view/:id', 
				name: 'alamat_userview', 
				component: () => import('./pages/alamat_user/view.vue'), 
				props: true
			},
	
			{ 
				path: '/alamat_user/add', 
				name: 'alamat_useradd', 
				component: () => import('./pages/alamat_user/add.vue'), 
				props: true
			},
	
			{ 
				path: '/alamat_user/edit/:id', 
				name: 'alamat_useredit', 
				component: () => import('./pages/alamat_user/edit.vue'), 
				props: true
			},
		
			{
				path: '/alamat_user/list_admin/:index?/:fieldName?/:fieldValue?',
				name: 'alamat_userlist_admin',
				component: () => import('./pages/alamat_user/list_admin.vue'), 
				props: true
			},
			
	

//form_pemesanan routes
			{
				path: '/form_pemesanan/:index?/:fieldName?/:fieldValue?',
				name: 'form_pemesananlist',
				component: () => import('./pages/form_pemesanan/list.vue'), 
				props: true
			},
			
	
			{ 
				path: '/form_pemesanan/view/:id', 
				name: 'form_pemesananview', 
				component: () => import('./pages/form_pemesanan/view.vue'), 
				props: true
			},
	
			{ 
				path: '/form_pemesanan/add', 
				name: 'form_pemesananadd', 
				component: () => import('./pages/form_pemesanan/add.vue'), 
				props: true
			},
	
			{ 
				path: '/form_pemesanan/edit/:id', 
				name: 'form_pemesananedit', 
				component: () => import('./pages/form_pemesanan/edit.vue'), 
				props: true
			},
		

//form_service routes

//keranjang routes
			{
				path: '/keranjang/:index?/:fieldName?/:fieldValue?',
				name: 'keranjanglist',
				component: () => import('./pages/keranjang/list.vue'), 
				props: true
			},
			
	
			{ 
				path: '/keranjang/view/:id', 
				name: 'keranjangview', 
				component: () => import('./pages/keranjang/view.vue'), 
				props: true
			},
	
			{ 
				path: '/keranjang/add', 
				name: 'keranjangadd', 
				component: () => import('./pages/keranjang/add.vue'), 
				props: true
			},
	
			{ 
				path: '/keranjang/edit/:id', 
				name: 'keranjangedit', 
				component: () => import('./pages/keranjang/edit.vue'), 
				props: true
			},
		
			{
				path: '/keranjang/list_user/:index?/:fieldName?/:fieldValue?',
				name: 'keranjanglist_user',
				component: () => import('./pages/keranjang/list_user.vue'), 
				props: true
			},
			
	
			{
				path: '/keranjang/laporan_penjualan/:index?/:fieldName?/:fieldValue?',
				name: 'keranjanglaporan_penjualan',
				component: () => import('./pages/keranjang/laporan_penjualan.vue'), 
				props: true
			},
			
	

//master_pengiriman routes
			{
				path: '/master_pengiriman/:index?/:fieldName?/:fieldValue?',
				name: 'master_pengirimanlist',
				component: () => import('./pages/master_pengiriman/list.vue'), 
				props: true
			},
			
	
			{ 
				path: '/master_pengiriman/view/:id', 
				name: 'master_pengirimanview', 
				component: () => import('./pages/master_pengiriman/view.vue'), 
				props: true
			},
	
			{ 
				path: '/master_pengiriman/add', 
				name: 'master_pengirimanadd', 
				component: () => import('./pages/master_pengiriman/add.vue'), 
				props: true
			},
	
			{ 
				path: '/master_pengiriman/edit/:id', 
				name: 'master_pengirimanedit', 
				component: () => import('./pages/master_pengiriman/edit.vue'), 
				props: true
			},
		

//master_status routes
			{
				path: '/master_status/:index?/:fieldName?/:fieldValue?',
				name: 'master_statuslist',
				component: () => import('./pages/master_status/list.vue'), 
				props: true
			},
			
	
			{ 
				path: '/master_status/add', 
				name: 'master_statusadd', 
				component: () => import('./pages/master_status/add.vue'), 
				props: true
			},
	
			{ 
				path: '/master_status/edit/:id', 
				name: 'master_statusedit', 
				component: () => import('./pages/master_status/edit.vue'), 
				props: true
			},
		

//master_status_barang routes
			{
				path: '/master_status_barang/:index?/:fieldName?/:fieldValue?',
				name: 'master_status_baranglist',
				component: () => import('./pages/master_status_barang/list.vue'), 
				props: true
			},
			
	
			{ 
				path: '/master_status_barang/view/:id', 
				name: 'master_status_barangview', 
				component: () => import('./pages/master_status_barang/view.vue'), 
				props: true
			},
	
			{ 
				path: '/master_status_barang/add', 
				name: 'master_status_barangadd', 
				component: () => import('./pages/master_status_barang/add.vue'), 
				props: true
			},
	
			{ 
				path: '/master_status_barang/edit/:id', 
				name: 'master_status_barangedit', 
				component: () => import('./pages/master_status_barang/edit.vue'), 
				props: true
			},
		

//master_status_service routes

//pembayaran routes
			{
				path: '/pembayaran/:index?/:fieldName?/:fieldValue?',
				name: 'pembayaranlist',
				component: () => import('./pages/pembayaran/list.vue'), 
				props: true
			},
			
	
			{ 
				path: '/pembayaran/view/:id', 
				name: 'pembayaranview', 
				component: () => import('./pages/pembayaran/view.vue'), 
				props: true
			},
	
			{ 
				path: '/pembayaran/add', 
				name: 'pembayaranadd', 
				component: () => import('./pages/pembayaran/add.vue'), 
				props: true
			},
	
			{ 
				path: '/pembayaran/edit/:id', 
				name: 'pembayaranedit', 
				component: () => import('./pages/pembayaran/edit.vue'), 
				props: true
			},
		
			{
				path: '/pembayaran/list_admin/:index?/:fieldName?/:fieldValue?',
				name: 'pembayaranlist_admin',
				component: () => import('./pages/pembayaran/list_admin.vue'), 
				props: true
			},
			
	
			{
				path: '/pembayaran/validasi_pembayaran/:index?/:fieldName?/:fieldValue?',
				name: 'pembayaranvalidasi_pembayaran',
				component: () => import('./pages/pembayaran/validasi_pembayaran.vue'), 
				props: true
			},
			
	
			{ 
				path: '/pembayaran/upload_bukti/:id', 
				name: 'pembayaranupload_bukti', 
				component: () => import('./pages/pembayaran/upload_bukti.vue'), 
				props: true
			},
		

//permissions routes
			{
				path: '/permissions/:index?/:fieldName?/:fieldValue?',
				name: 'permissionslist',
				component: () => import('./pages/permissions/list.vue'), 
				props: true
			},
			
	
			{ 
				path: '/permissions/view/:id', 
				name: 'permissionsview', 
				component: () => import('./pages/permissions/view.vue'), 
				props: true
			},
	
			{ 
				path: '/permissions/add', 
				name: 'permissionsadd', 
				component: () => import('./pages/permissions/add.vue'), 
				props: true
			},
	
			{ 
				path: '/permissions/edit/:id', 
				name: 'permissionsedit', 
				component: () => import('./pages/permissions/edit.vue'), 
				props: true
			},
		

//produk routes
			{
				path: '/produk/:index?/:fieldName?/:fieldValue?',
				name: 'produklist',
				component: () => import('./pages/produk/list.vue'), 
				props: true
			},
			
	
			{ 
				path: '/produk/view/:id', 
				name: 'produkview', 
				component: () => import('./pages/produk/view.vue'), 
				props: true
			},
	
			{ 
				path: '/produk/add', 
				name: 'produkadd', 
				component: () => import('./pages/produk/add.vue'), 
				props: true
			},
	
			{ 
				path: '/produk/edit/:id', 
				name: 'produkedit', 
				component: () => import('./pages/produk/edit.vue'), 
				props: true
			},
		
			{
				path: '/produk/dashboard/:index?/:fieldName?/:fieldValue?',
				name: 'produkdashboard',
				component: () => import('./pages/produk/dashboard.vue'), 
				props: true
			},
			
	
			{ 
				path: '/produk/view_user/:id', 
				name: 'produkview_user', 
				component: () => import('./pages/produk/view_user.vue'), 
				props: true
			},
		
			{
				path: '/produk/no_login/:index?/:fieldName?/:fieldValue?',
				name: 'produkno_login',
				component: () => import('./pages/produk/no_login.vue'), 
				props: true
			},
			
	

//produk_kategori routes
			{
				path: '/produk_kategori/:index?/:fieldName?/:fieldValue?',
				name: 'produk_kategorilist',
				component: () => import('./pages/produk_kategori/list.vue'), 
				props: true
			},
			
	
			{ 
				path: '/produk_kategori/view/:id', 
				name: 'produk_kategoriview', 
				component: () => import('./pages/produk_kategori/view.vue'), 
				props: true
			},
	
			{ 
				path: '/produk_kategori/add', 
				name: 'produk_kategoriadd', 
				component: () => import('./pages/produk_kategori/add.vue'), 
				props: true
			},
	
			{ 
				path: '/produk_kategori/edit/:id', 
				name: 'produk_kategoriedit', 
				component: () => import('./pages/produk_kategori/edit.vue'), 
				props: true
			},
		

//roles routes
			{
				path: '/roles/:index?/:fieldName?/:fieldValue?',
				name: 'roleslist',
				component: () => import('./pages/roles/list.vue'), 
				props: true
			},
			
	
			{ 
				path: '/roles/view/:id', 
				name: 'rolesview', 
				component: () => import('./pages/roles/view.vue'), 
				props: true
			},
	
			{ 
				path: '/roles/add', 
				name: 'rolesadd', 
				component: () => import('./pages/roles/add.vue'), 
				props: true
			},
	
			{ 
				path: '/roles/edit/:id', 
				name: 'rolesedit', 
				component: () => import('./pages/roles/edit.vue'), 
				props: true
			},
		

//service routes

//user routes
			{
				path: '/user/:index?/:fieldName?/:fieldValue?',
				name: 'userlist',
				component: () => import('./pages/user/list.vue'), 
				props: true
			},
			
	
			{ 
				path: '/user/view/:id', 
				name: 'userview', 
				component: () => import('./pages/user/view.vue'), 
				props: true
			},
	
			{ 
				path: '/index/register', 
				name: 'useruserregister', 
				component: () => import('./pages/index/userregister.vue'), 
				props: true
			},
	
			{ 
				path: '/account/edit', 
				name: 'useraccountedit', 
				component: () => import('./pages/account/accountedit.vue'), 
				props: true
			},
	
			{ 
				path: '/account', 
				name: 'useraccountview', 
				component: () => import('./pages/account/accountview.vue'), 
				props: true
			},
	
			{ 
				path: '/user/add', 
				name: 'useradd', 
				component: () => import('./pages/user/add.vue'), 
				props: true
			},
	
			{ 
				path: '/user/edit/:id', 
				name: 'useredit', 
				component: () => import('./pages/user/edit.vue'), 
				props: true
			},
		

	
	
//Password reset routes
			{ 
				path: '/index/forgotpassword', 
				name: 'forgotpassword', 
				component: () => import('./pages/index/forgotpassword.vue'), 
				props: true
			},
			{ 
				path: '/index/resetpassword', 
				name: 'resetpassword', 
				component: () => import('./pages/index/resetpassword.vue'), 
				props: true
			},
			{ 
				path: '/index/resetpassword_completed', 
				name: 'resetpassword_completed', 
				component: () => import('./pages/index/resetpassword_completed.vue'), 
				props: true
			},
	
	
	
	{ 
		path:  '/error/forbidden', 
		name: 'forbidden', 
		component: () => import('./pages/errors/forbidden.vue'),
		props: true
	},
	{ 
		path: '/error/notfound', 
		name: 'notfound',
		component: () => import('./pages/errors/pagenotfound.vue'),
		props: true
	},
	{
		path: '/:catchAll(.*)', 
		component: () => import('./pages/errors/pagenotfound.vue')
	}
];

export default () => {
	
const auth = useAuth();

	
	const user = auth.user;
	if(user){
		const roleName = auth.userRole.toLowerCase();
		let rolePage;
		switch(roleName){
			case "user":
				rolePage = "user";
				break;
			case "admin":
				rolePage = "admin";
				break;
			default:
				rolePage = "home";
		}
		
		//Dashboard route
		//Display page based on user role
		routes.push({
			path: '/',
			alias: '/home', 
			name: 'home', 
			component: () => import(`./pages/home/${rolePage}.vue`),
			props: true
		});
	}
	else{
		routes.push({
			path: '/', 
			alias: '/index', 
			name: 'index', 
			component: () => import('./pages/index/index.vue'),
			props: true
		});
	}

	const router = createRouter({
		history: createWebHashHistory(),
		routes,
		scrollBehavior(to, from, savedPostion){
			//if(savedPostion) return savedPostion;
			return { x:0, y:0 }
		}
	});
	
	router.beforeEach((to, from, next) => {
		const user = auth.user;
		let path = to.path;
		let authRequired = auth.pageRequiredAuth(path);
		if (authRequired) {
			if(!user){
				return next({ path: '/',  query: { nexturl: to.fullPath } });
			}
			//authorize user
			if (!auth.canView(path)) {
				return next({path: "/error/forbidden"});
			}
		}

		if(user && to.name == "index"){
			//already logged in, show home when try to access index page
			return next({ path: "/home"});
		}

		//navigate to redirect url if available
		if(to.name == "home" && to.query.nexturl){
			return next({ path: to.query.nexturl});
		}

 		//close dialog from previous page
		//store.closeDialogs('app/closeDialogs');
		
		next();
	});

	return router;
}
